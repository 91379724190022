import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { isError } from "../../../../lib/utils";
import type { AccountRow } from "../AccountRow";
import { AccountActionsDelete } from "./AccountActionsDelete";
import classes from "./AccountActionsEdit.module.css";
import { AccountActionsWipe } from "./AccountActionsWipe";

interface AccountActionsEditProps {
  account: AccountRow;
  onChange: (account: AccountRow) => Promise<void>;
  onDelete: (account: AccountRow) => Promise<void>;
  onWipe: (account: AccountRow, reason: string) => Promise<void>;
}

export const AccountActionsEdit = (props: AccountActionsEditProps) => {
  const { account, onChange, onDelete, onWipe } = props;
  const { t } = useTranslation();

  const { name } = account;
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<
    "delete" | "wipe" | undefined
  >(undefined);
  const [open, setOpen] = useState(false);
  const [pendingName, setPendingName] = useState(name);
  const [dialogTitleId] = useState(() => uuid());
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleAccountWipe = useCallback(() => {
    setConfirmDialogOpen("wipe");
  }, []);

  const handleAccountDelete = useCallback(() => {
    setConfirmDialogOpen("delete");
  }, []);

  const handleConfirmDialogClose = useCallback(() => {
    setConfirmDialogOpen(undefined);
  }, []);

  const handleCancel = useCallback(() => {
    if (!loading) {
      setOpen(false);
    }
  }, [loading]);

  const handleClickOpen = useCallback(() => {
    setError(undefined);
    setLoading(false);
    setPendingName(name);

    setOpen(true);
  }, [name]);

  const handlePendingNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(undefined);
      setPendingName(event.currentTarget.value);
    },
    [],
  );

  const handleSave = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setError(undefined);
      setLoading(true);
      onChange({
        ...account,
        name: pendingName === "" ? undefined : pendingName,
      })
        .then(() => {
          setOpen(false);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error: unknown) => {
          if (isError(error)) {
            setError(error);
          }

          console.error(error);
        });
    },
    [account, onChange, pendingName],
  );

  return (
    <>
      <span className={classes.root}>
        <Tooltip title={String(t("AccountActionsEdit.button"))}>
          <IconButton onClick={handleClickOpen} size="small">
            <Edit />
          </IconButton>
        </Tooltip>
      </span>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby={dialogTitleId}
        fullWidth={true}
        maxWidth="xs"
      >
        <form onSubmit={handleSave}>
          <DialogTitle id={dialogTitleId}>
            {t("AccountActionsEdit.title")}
          </DialogTitle>
          {typeof error !== "undefined" && (
            <DialogContent>
              <Typography color="error" variant="body2">
                {error.toString()}
              </Typography>
            </DialogContent>
          )}
          <DialogContent className={classes.content}>
            <TextField
              autoFocus
              disabled={loading}
              fullWidth
              label={t("AccountActionsEdit.name")}
              margin="normal"
              onChange={handlePendingNameChange}
              value={pendingName}
            />
            <Button
              color="warning"
              onClick={handleAccountWipe}
              variant="contained"
            >
              {t("AccountActionsEdit.wipeAccount")}
            </Button>
            <Button
              color="error"
              onClick={handleAccountDelete}
              variant="contained"
            >
              {t("AccountActionsEdit.deleteAccount")}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleCancel} color="primary">
              {t("AccountActionsEdit.cancel")}
            </Button>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
            >
              {t("AccountActionsEdit.save")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <AccountActionsDelete
        account={account}
        onClose={handleConfirmDialogClose}
        onDelete={onDelete}
        open={confirmDialogOpen === "delete"}
      />
      <AccountActionsWipe
        account={account}
        onClose={handleConfirmDialogClose}
        onWipe={onWipe}
        open={confirmDialogOpen === "wipe"}
      />
    </>
  );
};
