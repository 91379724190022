import People from "@mui/icons-material/People";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useApi } from "../../../../../api";
import { Picker, SingleSelect } from "../../../../../components/Picker";
import {
  HwidOptions,
  IpOptions,
} from "../../../../../components/PickerOptions";
import type { AccountRow } from "../../AccountRow";
import classes from "./AccountActionsMultiAccount.module.css";
import { HwidMatchMode } from "./HwidMatchMode";
import { HwidMatchModePicker } from "./HwidMatchModePicker";
import { MultiAccountsTable } from "./MultiAccountsTable";

interface AccountActionsMultiAccountProps {
  account: AccountRow;
}

export const AccountActionsMultiAccount = (
  props: AccountActionsMultiAccountProps,
) => {
  const { account } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [dialogTitleId] = useState(() => uuid());
  const [hwid, setHwid] = useState<string | undefined>(undefined);
  const [hwidMatchMode, setHwidMatchMode] = useState<HwidMatchMode>(
    HwidMatchMode.Lax,
  );
  const [ip, setIp] = useState<string | undefined>(undefined);

  const { api } = useApi();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickOpen = useCallback(() => {
    setHwid(undefined);
    setIp(undefined);

    setOpen(true);
  }, []);

  return (
    <>
      <span className={classes.root}>
        <Tooltip title={String(t("AccountActionsMultiAccount.button"))}>
          <IconButton onClick={handleClickOpen} size="small">
            <People />
          </IconButton>
        </Tooltip>
      </span>
      <Dialog
        aria-labelledby={dialogTitleId}
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id={dialogTitleId}>
          {t("AccountActionsMultiAccount.title")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.filters}>
            <div className={classes.hwidFilter}>
              <Picker
                allowEmpty
                fullWidth
                label={t("AccountActionsBan.hwid")}
                margin="dense"
                size="small"
              >
                <SingleSelect onChange={setHwid} value={hwid} />
                <HwidOptions accountId={account.id} api={api} />
              </Picker>
              <HwidMatchModePicker
                onChange={setHwidMatchMode}
                value={hwidMatchMode}
              />
            </div>
            <Picker
              allowEmpty
              fullWidth
              label={t("AccountActionsMultiAccount.ip")}
              margin="dense"
              size="small"
            >
              <SingleSelect onChange={setIp} value={ip} />
              <IpOptions accountId={account.id} api={api} />
            </Picker>
          </div>
          <div className={classes.table}>
            {hwid === undefined && ip === undefined ? (
              <Typography align="center">
                {t("AccountActionsMultiAccount.defaultSelections")}
              </Typography>
            ) : (
              <MultiAccountsTable
                hwid={hwid}
                hwidMatchMode={hwidMatchMode}
                ip={ip}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("AccountActionsMultiAccount.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
