import ListIcon from "@mui/icons-material/List";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { Deck, useApi } from "../../../../api";
import { Picker, SingleSelect } from "../../../../components/Picker";
import { DeckOptions } from "../../../../components/PickerOptions";
import { isError } from "../../../../lib/utils";
import type { AccountRow } from "../AccountRow";
import classes from "./AccountActionsDecks.module.css";

interface AccountActionsDecksProps {
  account: AccountRow;
}

export const AccountActionsDecks = (props: AccountActionsDecksProps) => {
  const { account } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [dialogTitleId] = useState(() => uuid());
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [deck, setDeck] = useState<Deck | undefined>(undefined);
  const [pendingName, setPendingName] = useState("");

  const { api } = useApi();

  const handleCancel = useCallback(() => {
    if (!loading) {
      setOpen(false);
    }
  }, [loading]);

  const handleClickOpen = useCallback(() => {
    setDeck(undefined);
    setError(undefined);
    setLoading(false);
    setPendingName("");

    setOpen(true);
  }, []);

  const handlePendingNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(undefined);
      setPendingName(event.currentTarget.value);
    },
    [],
  );

  const handleSave = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (typeof deck === "undefined") {
        setError(t("AccountActionsDecks.error.noDeckSelected"));
      } else {
        setError(undefined);
        setLoading(true);

        await api
          .updateDeck({
            id: deck.id,
            name: pendingName,
          })
          .then(() => {
            setOpen(false);
          })
          .finally(() => {
            setLoading(false);
          })
          .catch((error: unknown) => {
            console.error(error);

            let niceError;

            if (isError(error)) {
              niceError = error.message;
            } else if (error instanceof Response) {
              if (error.status === 404) {
                niceError = t("AccountActionsDecks.error.deckNotFound");
              } else if (error.status === 422) {
                niceError = t("AccountActionsDecks.error.invalidDeckName");
              }
            }

            setError(
              niceError ?? t("AccountActionsDecks.error.unexpectedError"),
            );
          });
      }
    },
    [api, deck, pendingName, t],
  );

  useEffect(() => {
    setPendingName(deck?.name ?? "");
    setLoading(false);
  }, [deck]);

  return (
    <>
      <span className={classes.root}>
        <Tooltip title={String(t("AccountActionsDecks.button"))}>
          <IconButton onClick={handleClickOpen} size="small">
            <ListIcon />
          </IconButton>
        </Tooltip>
      </span>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby={dialogTitleId}
        fullWidth={true}
        maxWidth="xs"
      >
        <form onSubmit={handleSave}>
          <DialogTitle id={dialogTitleId}>
            {t("AccountActionsDecks.title")}
          </DialogTitle>
          {typeof error !== "undefined" && (
            <DialogContent>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </DialogContent>
          )}
          <DialogContent>
            <Picker
              allowEmpty
              disabled={loading}
              fullWidth
              label={t("AccountActionsDecks.decks")}
              margin="dense"
            >
              <SingleSelect onChange={setDeck} value={deck} />
              <DeckOptions accountId={account.id} api={api} />
            </Picker>
            <TextField
              disabled={loading || typeof deck === "undefined"}
              fullWidth
              label={t("AccountActionsDecks.newName")}
              margin="dense"
              onChange={handlePendingNameChange}
              value={pendingName}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={handleCancel} color="primary">
              {t("AccountActionsDecks.cancel")}
            </Button>
            <LoadingButton
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
            >
              {t("AccountActionsDecks.save")}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
