import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { v4 as uuid } from "uuid";
import { isError } from "../../../../lib/utils";
import type { AccountRow } from "../AccountRow";
import classes from "./AccountActionsConfirmDialog.module.css";

interface AccountActionsConfirmDialogProps {
  account: AccountRow;
  children: ReactNode;
  confirmIcon: ReactNode;
  locals: {
    cancel: string;
    confirm: string;
    confirmName: string;
    info: string;
    title: string;
  };
  onClose: () => void;
  onConfirm: (account: AccountRow) => Promise<void>;
  open: boolean;
}

export const AccountActionsConfirmDialog = (
  props: AccountActionsConfirmDialogProps,
) => {
  const { account, children, confirmIcon, locals, onClose, onConfirm, open } =
    props;

  const { name } = account;
  const [confirmName, setConfirmName] = useState("");
  const dialogTitleId = useMemo(() => uuid(), []);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleCancel = useCallback(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  useLayoutEffect(() => {
    if (open) {
      setError(undefined);
      setLoading(false);
      setConfirmName("");
    }
  }, [open]);

  const handleConfirmNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(undefined);
      setConfirmName(event.currentTarget.value);
    },
    [],
  );

  const handleConfirm = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      setError(undefined);
      setLoading(true);
      onConfirm(account)
        .then(() => {
          onClose();
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error: unknown) => {
          if (isError(error)) {
            setError(error);
          }

          console.error(error);
        });
    },
    [account, onClose, onConfirm],
  );

  const validConfirmName = useMemo(
    () =>
      name === undefined || confirmName.toLowerCase() === name.toLowerCase(),
    [name, confirmName],
  );

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby={dialogTitleId}
      fullWidth={true}
      maxWidth="xs"
    >
      <form onSubmit={handleConfirm}>
        <DialogTitle id={dialogTitleId}>{locals.title}</DialogTitle>
        <DialogContent className={classes.content}>
          {typeof error !== "undefined" && (
            <Typography color="error">{error.toString()}</Typography>
          )}

          {children}

          <TextField
            autoFocus
            disabled={loading}
            fullWidth
            label={locals.confirmName}
            margin="dense"
            onChange={handleConfirmNameChange}
            value={confirmName}
          />
        </DialogContent>

        <DialogActions>
          <Button disabled={loading} onClick={handleCancel}>
            {locals.cancel}
          </Button>
          <LoadingButton
            color="error"
            disabled={!validConfirmName}
            loading={loading}
            loadingPosition="start"
            startIcon={confirmIcon}
            type="submit"
            variant="contained"
          >
            {locals.confirm}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
